<template>
  <v-radio-group :value="accountType" hide-details="auto" row @change="changeAccountType($event)">
    <v-radio
      :id="seleniumIds.SELENIUM_TEST_REGISTER_TYPE_PERSONAL_FIELD"
      value="personal"
      active-class="label-active"
    >
      <template #label>
        <div class="text-uppercase text-subtitle-2 black--text">
          {{ $t('register.register_type_personal') }}
        </div>
      </template>
    </v-radio>
    <v-radio
      :id="seleniumIds.SELENIUM_TEST_REGISTER_TYPE_COMPANY_FIELD"
      value="company"
      active-class="label-active"
    >
      <template #label>
        <div class="text-uppercase text-subtitle-2 black--text">
          {{ $t('register.register_type_company') }}
        </div>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'RegisterType',
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('register', ['accountType'])
    },
    methods: {
      ...mapActions('register', ['setAccountType', 'resetCompanyFields']),
      changeAccountType($event) {
        this.setAccountType($event)
        this.resetCompanyFields()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .label-active div {
    color: var(--v-primary-base) !important;
  }
</style>
