<template>
  <div>
    <c-autocomplete
      v-if="messengers.length"
      :id="seleniumIds.SELENIUM_TEST_REGISTER_SELECT_MESSENGERS_FIELD"
      v-model="userMessenger"
      :items="messengers"
      :all-selected-is-active="false"
      :multiple="false"
      item-text="name"
      item-value="id"
      selection
      height="auto"
      :label="$t('register.fields.network')"
      hide-no-data
      return-object
      hide-details="auto"
      :loading="messengersAreLoading"
    />
    <field-nickname
      v-for="(messenger, index) in selectedMessengers"
      :key="messenger.id"
      :index="index"
      :messenger="messenger"
      class="mt-2"
      @remove-field="deleteFieldNickname(messenger.id)"
    />
  </div>
</template>

<script>
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'
  import { mapActions, mapState } from 'vuex'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import FieldNickname from './FieldNickname.vue'

  export default {
    name: 'SocialNetworks',
    components: {
      CAutocomplete,
      FieldNickname
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('collections', ['messengers', 'messengersAreLoading']),
      ...mapState('register', ['selectedMessengers']),
      userMessenger: {
        get() {
          return this.selectedMessengers[0]
        },
        set(value) {
          this.setSelectedMessengers([value])
          if (this.selectedMessengers.length === 0) {
            this.setSelectedMessengers([this.messengers[0]])
          }
        }
      }
    },
    async created() {
      await this.fetchMessengersList()
      if (this.messengers !== 0) {
        this.setSelectedMessengers([this.messengers[0]])
      }
    },
    methods: {
      ...mapActions('collections', ['fetchMessengersList']),
      ...mapActions('register', ['setSelectedMessengers']),
      deleteFieldNickname(messengerId) {
        const filteredMessengers = this.selectedMessengers.filter(({ id }) => id !== messengerId)
        this.setSelectedMessengers(filteredMessengers)
      }
    }
  }
</script>
