<template>
  <v-row>
    <v-col cols="12" sm="6" class="pa-2">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_REGISTER_NAME_FIELD"
        :value="name"
        type="text"
        class="text-caption"
        hide-details="auto"
        :label="$t('register.fields.name')"
        @input="setName($event)"
      />
    </v-col>

    <v-col cols="12" sm="6" class="pa-2">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_REGISTER_EMAIL_FIELD"
        :value="email"
        type="text"
        autocomplete="off"
        hide-details="auto"
        class="text-caption"
        :error-messages="errors.email"
        @input="setEmail($event)"
        @focus="resetError('email')"
      >
        <template #label>
          {{ $t('register.fields.email') }}
          <span class="error--text">*</span>
        </template>
      </c-text-field>
    </v-col>

    <v-col cols="12" sm="6" class="pa-2">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_REGISTER_PASSWORD_FIELD"
        :value="password"
        :type="passwordType"
        autocomplete="off"
        error-count="2"
        hide-details="auto"
        class="global-cursor-pointer text-caption"
        :error-messages="errors.password"
        @input="setPassword($event)"
      >
        <template #label>
          {{ $t('register.fields.password') }}

          <v-tooltip bottom color="black">
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1 mb-1" v-bind="attrs" size="14" color="primary" v-on="on">
                $information
              </v-icon>
            </template>
            {{ $t('register.fields.password_tooltip') }}
          </v-tooltip>
        </template>
        <template #append>
          <v-icon color="primary" size="24" @click="passwordIsShown(passwordType)">
            {{ passwordIcon }}
          </v-icon>
        </template>
      </c-text-field>
    </v-col>

    <v-col cols="12" sm="6" class="pa-2">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_REGISTER_PASSWORD_CONFIRM_FIELD"
        :value="passwordConfirmation"
        :type="passwordConfirmationType"
        autocomplete="off"
        error-count="2"
        hide-details="auto"
        class="global-cursor-pointer text-caption"
        :error-messages="errors.password"
        :label="$t('register.fields.confirm_password')"
        @input="setPasswordConfirmation($event)"
      >
        <template #append>
          <v-icon
            color="primary"
            size="24"
            @click="passwordConfirmationIsShown(passwordConfirmationType)"
          >
            {{ passwordConfirmationIcon }}
          </v-icon>
        </template>
      </c-text-field>
    </v-col>
  </v-row>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapActions, mapState } from 'vuex'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'CommonFields',
    components: {
      CTextField
    },
    data() {
      return {
        seleniumIds,
        passwordType: 'password',
        passwordConfirmationType: 'password'
      }
    },
    computed: {
      ...mapState('register', ['name', 'password', 'passwordConfirmation', 'email', 'errors']),
      passwordIcon() {
        return this.passwordType === 'password' ? 'mdi-eye-off' : 'mdi-eye'
      },
      passwordConfirmationIcon() {
        return this.passwordConfirmationType === 'password' ? 'mdi-eye-off' : 'mdi-eye'
      }
    },
    methods: {
      ...mapActions('register', [
        'setName',
        'setEmail',
        'setPassword',
        'setPasswordConfirmation',
        'resetError'
      ]),
      passwordIsShown(passwordType) {
        this.passwordType = passwordType === 'password' ? 'text' : 'password'
      },
      passwordConfirmationIsShown(passwordType) {
        this.passwordConfirmationType = passwordType === 'password' ? 'text' : 'password'
      }
    }
  }
</script>
