<template>
  <v-row>
    <v-col cols="12" sm="6" class="pa-2">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_REGISTER_COMPANY_NAME_FIELD"
        :value="companyName"
        type="text"
        hide-details="auto"
        :error-messages="errors.company_name"
        @input="setCompanyName($event)"
        @focus="resetError('company_name')"
      >
        <template #label>
          <div class="text-caption">
            {{ $t('register.fields.company_name') }}
            <span class="error--text">*</span>
          </div>
        </template>
      </c-text-field>
    </v-col>

    <v-col cols="12" sm="6" class="pa-2">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_REGISTER_VALUE_ADDED_TAX_FIELD"
        :value="valueAddedTax"
        type="text"
        hide-details="auto"
        :label="$t('register.fields.value_added')"
        @input="setValueAddedTax($event)"
      />
    </v-col>

    <v-col cols="12" sm="6" class="pa-2">
      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_REGISTER_COMPANY_ADDRESS_FIELD"
        :value="companyAddress"
        type="text"
        hide-details="auto"
        :error-messages="errors.company_address"
        @input="setCompanyAddress($event)"
        @focus="resetError('company_address')"
      >
        <template #label>
          <div class="text-caption">
            {{ $t('register.fields.company_address') }}
            <span class="error--text">*</span>
          </div>
        </template>
      </c-text-field>

      <c-text-field
        :id="seleniumIds.SELENIUM_TEST_REGISTER_COMPANY_SITE_FIELD"
        :value="companySite"
        type="text"
        hide-details="auto"
        class="mt-2"
        :error-messages="errors.company_site"
        @input="setCompanySite($event)"
        @focus="resetError('company_site')"
      >
        <template #label>
          <div class="text-caption">
            {{ $t('register.fields.company_site') }}
            <span class="error--text">*</span>
          </div>
        </template>
      </c-text-field>
    </v-col>

    <v-col cols="12" sm="6" class="pa-2">
      <c-textarea
        :id="seleniumIds.SELENIUM_TEST_REGISTER_ADDITIONAL_INFO_FIELD"
        :value="additionalInfo"
        no-resize
        hide-details="auto"
        v-bind="textareaSettings"
        :label=" $t('register.fields.additional_info')"
        @input="setAdditionalInfo($event)"
      />
    </v-col>
  </v-row>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import { mapActions, mapState } from 'vuex'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'CompanyField',
    components: {
      CTextField,
      CTextarea
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('register', [
        'companyName',
        'valueAddedTax',
        'companyAddress',
        'companySite',
        'additionalInfo',
        'errors'
      ]),
      textareaSettings() {
        return this.$vuetify.breakpoint.xsOnly ? { rows: 2 } : { rows: 3, height: '114px' }
      }
    },
    methods: {
      ...mapActions('register', [
        'setCompanyName',
        'setValueAddedTax',
        'setCompanyAddress',
        'setCompanySite',
        'setAdditionalInfo',
        'resetError'
      ])
    }
  }
</script>
