<template>
  <v-container>
    <common-fields />

    <company-fields v-if="accountType === 'company'" />

    <v-row>
      <v-col cols="12">
        <social-networks />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import CommonFields from './CommonFields.vue'
  import CompanyFields from './CompanyFields.vue'

  import SocialNetworks from './SocialNetworks/Index.vue'

  export default {
    name: 'RegisterForm',
    components: {
      SocialNetworks,
      CommonFields,
      CompanyFields
    },
    computed: {
      ...mapState('register', ['accountType'])
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .mdi-menu-down::before {
    color: var(--v-primary-base);
  }
</style>
